.css-base {
  /* Fix stuff that we overwrite on accident */
  /* Overwrite bootstrap CSS that can't be configured */
}
.css-base .bootstrap-select.btn-group .dropdown-menu li {
  list-style-type: none;
  left: 0;
  margin-right: 0;
}
.css-base .container {
  padding: 0;
}
.css-base .btn-grey {
  color: #ffffff;
  background-color: #999999;
  border-color: #666666;
  border-color: #999999;
}
.css-base .btn-grey:hover,
.css-base .btn-grey:focus,
.css-base .btn-grey:active,
.css-base .btn-grey.active,
.open .dropdown-toggle.css-base .btn-grey {
  color: #ffffff;
  background-color: #858585;
  border-color: #474747;
}
.css-base .btn-grey:active,
.css-base .btn-grey.active,
.open .dropdown-toggle.css-base .btn-grey {
  background-image: none;
}
.css-base .btn-grey.disabled,
.css-base .btn-grey[disabled],
fieldset[disabled] .css-base .btn-grey,
.css-base .btn-grey.disabled:hover,
.css-base .btn-grey[disabled]:hover,
fieldset[disabled] .css-base .btn-grey:hover,
.css-base .btn-grey.disabled:focus,
.css-base .btn-grey[disabled]:focus,
fieldset[disabled] .css-base .btn-grey:focus,
.css-base .btn-grey.disabled:active,
.css-base .btn-grey[disabled]:active,
fieldset[disabled] .css-base .btn-grey:active,
.css-base .btn-grey.disabled.active,
.css-base .btn-grey[disabled].active,
fieldset[disabled] .css-base .btn-grey.active {
  background-color: #999999;
  border-color: #666666;
}
.css-base .btn-grey:hover,
.css-base .btn-grey:focus,
.css-base .btn-grey:active,
.css-base .btn-grey.active,
.open .dropdown-toggle.css-base .btn-grey {
  background-color: #666666;
  border-color: #666666;
}
.css-base .btn-grey:active,
.css-base .btn-grey.active,
.open .dropdown-toggle.css-base .btn-grey {
  background-image: none;
}
.css-base .btn-grey.disabled,
.css-base .btn-grey[disabled],
fieldset[disabled] .css-base .btn-grey,
.css-base .btn-grey.disabled:hover,
.css-base .btn-grey[disabled]:hover,
fieldset[disabled] .css-base .btn-grey:hover,
.css-base .btn-grey.disabled:focus,
.css-base .btn-grey[disabled]:focus,
fieldset[disabled] .css-base .btn-grey:focus,
.css-base .btn-grey.disabled:active,
.css-base .btn-grey[disabled]:active,
fieldset[disabled] .css-base .btn-grey:active,
.css-base .btn-grey.disabled.active,
.css-base .btn-grey[disabled].active,
fieldset[disabled] .css-base .btn-grey.active {
  background-color: #999999;
  border-color: #999999;
  color: #c9c9c9;
}
.css-base .btn {
  border-style: none;
}
.css-base .btn.disabled,
.css-base .btn[disabled],
fieldset[disabled] .css-base .btn {
  color: #fff;
  opacity: 1;
  filter: alpha(opacity=100);
  background-color: #dedede;
}
.css-base .btn-group .btn {
  margin: 0 15px 15px 0;
}
.css-base .form-group .col-xs-1,
.css-base .form-group .col-xs-2,
.css-base .form-group .col-xs-3,
.css-base .form-group .col-xs-4,
.css-base .form-group .col-xs-5,
.css-base .form-group .col-xs-6,
.css-base .form-group .col-xs-7,
.css-base .form-group .col-xs-8,
.css-base .form-group .col-xs-9,
.css-base .form-group .col-xs-10,
.css-base .form-group .col-xs-11,
.css-base .form-group .col-xs-12,
.css-base .form-group .col-sm-1,
.css-base .form-group .col-sm-2,
.css-base .form-group .col-sm-3,
.css-base .form-group .col-sm-4,
.css-base .form-group .col-sm-5,
.css-base .form-group .col-sm-6,
.css-base .form-group .col-sm-7,
.css-base .form-group .col-sm-8,
.css-base .form-group .col-sm-9,
.css-base .form-group .col-sm-10,
.css-base .form-group .col-sm-11,
.css-base .form-group .col-sm-12,
.css-base .form-group .col-md-1,
.css-base .form-group .col-md-2,
.css-base .form-group .col-md-3,
.css-base .form-group .col-md-4,
.css-base .form-group .col-md-5,
.css-base .form-group .col-md-6,
.css-base .form-group .col-md-7,
.css-base .form-group .col-md-8,
.css-base .form-group .col-md-9,
.css-base .form-group .col-md-10,
.css-base .form-group .col-md-11,
.css-base .form-group .col-md-12,
.css-base .form-group .col-lg-1,
.css-base .form-group .col-lg-2,
.css-base .form-group .col-lg-3,
.css-base .form-group .col-lg-4,
.css-base .form-group .col-lg-5,
.css-base .form-group .col-lg-6,
.css-base .form-group .col-lg-7,
.css-base .form-group .col-lg-8,
.css-base .form-group .col-lg-9,
.css-base .form-group .col-lg-10,
.css-base .form-group .col-lg-11,
.css-base .form-group .col-lg-12 {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.css-base .form-horizontal .form-group .help-block {
  margin-bottom: 0;
}
.css-base .form-horizontal .control-label {
  text-align: left;
}
.css-base .form-control:focus {
  border-color: #c9c9c9;
}
.css-base .btn-group.bootstrap-select button.btn.bs-noneSelected {
  color: #999999;
}
@media screen and (max-width: 767px) {
  .css-base .form-horizontal .control-label {
    padding-top: 0;
    margin-bottom: 0;
  }
}
.css-base .dropup .btn-default .caret {
  border-bottom-color: #000;
}
.css-base body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Lucida Grande', Tahoma, sans-serif;
  font-size: 13px;
  margin: 0;
  padding: 0;
  text-rendering: auto;
}
.css-base h1,
.css-base h2,
.css-base h3,
.css-base h4 {
  color: #333333;
  font-weight: bold;
  margin: 0 0 20px 0;
}
.css-base h1 {
  font-size: 22px;
}
.css-base h2 {
  font-size: 25px;
}
.css-base h3,
.css-base h4 {
  font-size: 13px;
}
.css-base p {
  color: #666666;
  font-size: 13px;
}
.css-base ul,
.css-base ol {
  padding: 0;
}
.css-base li {
  color: #333333;
  left: 15px;
  margin-right: 15px;
  position: relative;
}
.css-base ul li {
  list-style-type: square;
}
.css-base a:hover,
.css-base a:focus {
  text-decoration: none;
}
.css-base .navigation ul,
.css-base #navbar ul,
.css-base #breadcrumb ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.css-base .navigation ul:before,
.css-base #navbar ul:before,
.css-base #breadcrumb ul:before,
.css-base .navigation ul:after,
.css-base #navbar ul:after,
.css-base #breadcrumb ul:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.css-base .navigation ul:after,
.css-base #navbar ul:after,
.css-base #breadcrumb ul:after {
  clear: both;
}
.css-base .navigation ul li,
.css-base #navbar ul li,
.css-base #breadcrumb ul li {
  display: block;
  float: left;
  left: auto;
}
.css-base .navigation ul li,
.css-base .navigation ul li a {
  color: #8cbbdd;
  font-size: 12px;
}
.css-base .navigation ul.pull-left li {
  margin: 0 5px 0 0;
}
.css-base .navigation ul.pull-left li:after {
  content: '|';
  margin-left: 5px;
}
.css-base .navigation ul.pull-left li:last-child:after {
  content: '';
  margin-left: 0;
}
.css-base .navigation ul.pull-right li {
  margin: 0 0 0 5px;
}
.css-base .navigation:before,
.css-base .navigation:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.css-base .navigation:after {
  clear: both;
}
.css-base #blueheader #logo {
  display: inline-block;
  margin: 30.5px 15px 15px 15px;
}
@media screen and (min-width: 769px) {
  .css-base #blueheader #logo {
    width: auto;
    margin: 44px 15px 14px 0;
  }
}
.css-base #blueheader #logo img {
  max-width: 100%;
}
.css-base #blueheader #search-form {
  float: right;
  margin-top: 39px;
  margin-bottom: 14px;
  position: relative;
  width: 256px;
}
.css-base #blueheader #search-form input {
  background-color: #34648f;
  border: 0;
  color: #8cbbdd;
  width: 100%;
}
.css-base #blueheader #search-form input:focus {
  color: #666666;
  background-color: white;
  border: 1px solid #666666;
}
.css-base #blueheader #search-form input:focus + .btn {
  background: transparent url(/img/sprite-grundfos.png) -41px 0 no-repeat;
}
.css-base #blueheader #search-form button {
  background: transparent url(/img/sprite-grundfos.png) -17px 0 no-repeat;
  top: 5px;
  height: 23px;
  margin: 0;
  position: absolute;
  right: 5px;
}
.css-base #navbar ul li {
  box-sizing: border-box;
  margin: 0 0 0 40px;
  vertical-align: baseline;
  border-bottom: 4px solid transparent;
}
.css-base #navbar ul li:first-child {
  margin-left: 0;
}
.css-base #navbar ul li,
.css-base #navbar ul li a {
  color: #333333;
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  padding: 13px 0 12px 0;
}
.css-base #navbar ul li.active {
  border-bottom: 4px solid #3386c3;
}
.css-base #header-container {
  margin-bottom: 20px;
}
.css-base #breadcrumb {
  margin: 0 0 20px 0;
}
.css-base #breadcrumb ul li,
.css-base #breadcrumb ul li a {
  color: #666666;
  font-size: 13px;
}
.css-base #breadcrumb ul li {
  margin: 0 12px 0 0;
}
.css-base #breadcrumb ul li:after {
  content: '/';
  margin-left: 12px;
}
.css-base #main-container,
.css-base #bluefooter-container {
  overflow-x: hidden;
}
.css-base #global-navigation-container,
.css-base #blueheader-container,
.css-base #bluefooter-container {
  background-color: #11497b;
}
.css-base #global-navigation-container,
.css-base #navbar-container,
.css-base #search-form {
  display: none;
}
@media screen and (min-width: 769px) {
  .css-base #global-navigation-container,
  .css-base #navbar-container,
  .css-base #search-form {
    display: block;
  }
}
.css-base #footer-navigation {
  margin-top: 47px;
}
.css-base #navbar-container {
  background-color: #dedede;
  border-bottom: 1px solid #c9c9c9;
}
.css-base #bluefooter {
  color: #8cbbdd;
  font-size: 12px;
  padding: 15px 15px;
}
@media screen and (min-width: 769px) {
  .css-base #bluefooter {
    padding: 15px 0;
  }
}
.css-base #bluefooter p {
  color: #8cbbdd;
}
.css-base #bluefooter #be-think-innovate {
  color: #fff;
  font-family: helvetica, sans-serif;
  font-size: 16px;
  margin: -4px 0 10px 0;
  text-align: right;
}
.css-base #bluefooter .footer-divider {
  border-bottom: 1px solid #3386c3;
  margin-bottom: 15px;
}
.css-base #bluefooter div.firstline {
  color: #fff;
  font-size: 13px;
  margin-bottom: 8px;
}
.css-base .modulebox .form-group {
  margin: 0 15px 7.5px 15px;
}
.css-base .modulebox .checkbox,
.css-base .modulebox .radio {
  margin: 0 15px 7.5px 15px;
}
.css-base .modulebox .checkbox {
  padding-left: 20px;
}
.css-base .modulebox .checkbox input[type="checkbox"]:not(old) {
  display: none;
}
.css-base .modulebox .checkbox input[type="checkbox"]:not(old) + label:before {
  content: "\00a0";
  /* character: space */
  background: #fff;
  border: 1px solid #c9c9c9;
  font-family: 'Glyphicons Halflings';
  height: 16px;
  width: 16px;
  display: inline-block;
  font-size: 8px;
  line-height: 13px;
  margin-left: -20px;
  margin-bottom: -6px;
  margin-right: 0;
  padding: 0 0 0 0px;
  text-align: center;
}
.css-base .modulebox .checkbox input[type="checkbox"]:not(old):checked + label:before {
  content: "\e013";
}
.css-base .modulebox .radio {
  margin-bottom: 3px;
  padding-left: 0;
  padding-top: 0;
}
@media screen and (min-width: 768px) {
  .css-base .modulebox .radio {
    padding-top: 6.5px;
  }
}
.css-base .modulebox .radio input[type="radio"] {
  float: none;
}
.css-base .modulebox .radio input[type="radio"]:not(old) {
  display: none;
}
.css-base .modulebox .radio input[type="radio"]:not(old) + label:before {
  content: "\00a0";
  /* character: space */
  background: #fff;
  border: 1px solid #c9c9c9;
  border-radius: 8px 8px 8px 8px;
  font-family: 'Lucida Grande', Tahoma, sans-serif;
  height: 16px;
  width: 16px;
  display: inline-block;
  font-size: 18px;
  line-height: 12px;
  margin-left: -16px;
  margin-bottom: -3px;
  margin-right: 2px;
  overflow: hidden;
  padding: 0;
  text-align: center;
}
.css-base .modulebox .radio input[type="radio"]:not(old):checked + label:before {
  content: "\2022";
}
.css-base .modulebox .form-group p,
.css-base .modulebox .form-group .radio-inline {
  color: #999999;
  margin: 0;
  padding-top: 0;
  padding-bottom: 7.5px;
}
@media screen and (min-width: 768px) {
  .css-base .modulebox .form-group p,
  .css-base .modulebox .form-group .radio-inline {
    padding-top: 7px;
  }
}
.css-base .modulebox .form-group .radio-inline {
  padding-right: 0;
  padding-left: 15px;
  position: relative;
  top: -1.5px;
}
.css-base .modulebox .form-group .span-inline {
  display: inline-block;
}
.css-base .modulebox .form-group fieldset legend {
  border: 0;
  color: #666666;
  font-size: 13px;
  font-weight: bold;
}
.css-base .modulebox .form-group .help-block {
  display: none;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 4px;
}
.css-base .modulebox .form-group.has-error .help-block,
.css-base .modulebox .form-group.has-warning .help-block {
  display: inline-block;
}
.css-base .modulebox .form-horizontal .form-group.has-error .help-block,
.css-base .modulebox .form-horizontal .form-group.has-warning .help-block {
  display: block;
}
@media screen and (min-width: 768px) {
  .css-base .modulebox .form-group > .row {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .css-base .modulebox .row .form-group {
    margin-left: 0;
    margin-right: 0;
  }
}
.css-base .modulebox .form-horizontal .form-group {
  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-bottom: 7.5px;
}
.css-base .modulebox .form-horizontal .form-group .help-block {
  margin-top: -2.5px;
  margin-bottom: 15px;
}
.css-base .modulebox .form-horizontal .checkbox,
.css-base .modulebox .form-horizontal .radio {
  margin-left: 0;
  margin-right: 0;
}
.css-base .modulebox .form-control {
  margin-bottom: 7.5px;
  box-shadow: none;
}
.css-base .modulebox .form-control-static {
  min-height: 25.5px;
}
.css-base .modulebox label.required::after {
  content: "*";
}
.css-base .modulebox .input-group {
  margin-bottom: 7.5px;
}
.css-base .modulebox .input-group .form-control {
  margin-bottom: 0;
}
.css-base .modulebox .input-group .input-group-btn {
  border: 1px solid #c9c9c9;
}
.css-base .modulebox .input-group .input-group-btn:last-child {
  border-left: 0;
}
.css-base .modulebox .btn-group.bootstrap-select {
  margin: 0 0 7.5px;
}
.css-base .modulebox .btn-group.bootstrap-select .dropdown-menu {
  margin-top: 0;
}
.css-base .modulebox .btn-group.bootstrap-select .btn {
  background-color: #f2f2f2;
  border: 1px solid #c9c9c9;
  border-radius: 0;
  color: #000000;
  margin: 0;
}
.css-base .modulebox .btn-group.bootstrap-select .btn .bs-caret {
  background: #e4e4e4;
  border-left: 1px solid #c9c9c9;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
}
.css-base .modulebox .btn-group.bootstrap-select .btn .bs-caret .caret {
  border-top-color: #000000;
}
.css-base .modulebox .btn:hover .glyphicon,
.css-base .modulebox .btn:focus .glyphicon {
  color: #000;
}
.css-base .modal.fade .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: none;
  transition: none;
}
.css-base .modal .modal-content {
  border: 10px solid #000;
  border: 10px solid rgba(0, 0, 0, 0.5);
  box-shadow: none;
}
.css-base .modal .modal-content .modal-header {
  background-color: #dedede;
  border-bottom: 1px solid #c9c9c9;
  margin: 0 0 15px 0;
  padding: 11px;
}
.css-base .modal .modal-content .modal-header .modal-title {
  margin: 0;
}
.css-base .modal .modal-content .modal-header button.close {
  background-color: #333;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  margin-top: 0;
  opacity: 1;
  padding: 2px 4px 4px 4px;
  text-shadow: none;
}
.css-base .modal .modal-content .modal-header:before {
  background-color: #0068b4;
  content: "";
  display: block;
  height: 7px;
  margin: 0 -11px;
  position: relative;
  top: -11px;
}
.css-base .modal-backdrop {
  background-color: #fff;
}
.css-base .modal-backdrop.in {
  opacity: .7;
  filter: alpha(opacity=70);
}
.css-base .table-striped > thead > tr > th,
.css-base .table-striped > tbody > tr > th {
  background-color: #dedede;
}
.css-base .table-striped > tbody > tr:nth-child(odd) > td {
  background-color: #ffffff;
}
.css-base .table-striped > tbody > tr:nth-child(even) > td {
  background-color: #f2f2f2;
}
.css-base .table-hover > tbody > tr:nth-child(odd):hover > td {
  background-color: #f2f2f2;
}
.css-base .table-hover > tbody > tr:nth-child(even):hover > td {
  background-color: #dedede;
}
.css-base .cssbuild:after {
  content: "v0.3.0";
}
.css-base .headlinebar {
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}
.css-base .lead {
  color: #666666;
  font-size: 25px;
}
.css-base .note {
  color: #999999;
}
.css-base .gflabel {
  color: #333333;
}
.css-base .visuallyhidden {
  visibility: hidden;
  position: absolute;
}
.css-base .modulebox {
  background-color: #fff;
  border: 1px solid #c9c9c9;
  margin: 0 0 20px 0;
  padding-top: 15px;
}
.css-base .modulebox:before,
.css-base .modulebox:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.css-base .modulebox:after {
  clear: both;
}
.css-base .modulebox h1,
.css-base .modulebox h2,
.css-base .modulebox h3,
.css-base .modulebox h4,
.css-base .modulebox p,
.css-base .modulebox ul,
.css-base .modulebox ol {
  margin: 0 15px 15px 15px;
}
.css-base .modulebox h3.popover-title {
  margin: 0 0 15px 0 !important;
}
.css-base .modulebox .alert h4 {
  color: inherit;
}
.css-base .modulebox .alert h4 .glyphicon {
  font-size: 1.5em;
  margin-right: 0.2em;
  margin-top: -0.5em;
  top: .3em;
}
.css-base .modulebox .alert h4:only-child {
  margin: 0 15px 0 0;
}
.css-base .modulebox .btn-group {
  margin: 0 0 0 15px;
}
.css-base .modulebox > h3:first-child {
  background-color: #dedede;
  border-bottom: 1px solid #c9c9c9;
  margin: -15px 0 15px 0;
  padding: 15px;
}
.css-base .modulebox > h3:first-child + h4 {
  background-color: #f2f2f2;
  border-bottom: 1px solid #c9c9c9;
  margin: -15px 0 15px 0;
  padding: 15px;
}
.css-base .modulebox .image {
  margin: 0 15px 15px 15px;
}
.css-base .modulebox .row.col-sm-4-bg {
  margin: -15px 0 0 0;
  overflow: hidden;
}
.css-base .modulebox .row.col-sm-4-bg:before,
.css-base .modulebox .row.col-sm-4-bg:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.css-base .modulebox .row.col-sm-4-bg:after {
  clear: both;
}
.css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg .col-sm-8:before,
.css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg .col-sm-4:before,
.css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg .col-sm-8:after,
.css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg .col-sm-4:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg .col-sm-8:after,
.css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg .col-sm-4:after {
  clear: both;
}
.css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg > .col-sm-4,
.css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg > .col-sm-8 {
  padding-top: 15px;
}
.css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg > .col-sm-4 {
  background-color: #f2f2f2;
  border-top: 1px solid #c9c9c9;
}
@media screen and (min-width: 768px) {
  .css-base .modulebox .row.col-sm-4-bg {
    background-color: #f2f2f2;
  }
  .css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg {
    background-color: #fff;
    border-right: 1px solid #c9c9c9;
    float: left;
    position: relative;
    right: 33.333%;
  }
  .css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg .col-sm-8,
  .css-base .modulebox .row.col-sm-4-bg .col-sm-8-bg .col-sm-4 {
    background-color: transparent;
    border-style: none;
    left: 33.333%;
    padding: 15px 15px 0 15px;
  }
}
.css-base .modulebox .row {
  margin-left: 0;
  margin-right: 0;
}
.css-base .modulebox .row:before,
.css-base .modulebox .row:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.css-base .modulebox .row:after {
  clear: both;
}
.css-base .modulebox .row .col-xs-1,
.css-base .modulebox .row .col-xs-2,
.css-base .modulebox .row .col-xs-3,
.css-base .modulebox .row .col-xs-4,
.css-base .modulebox .row .col-xs-5,
.css-base .modulebox .row .col-xs-6,
.css-base .modulebox .row .col-xs-7,
.css-base .modulebox .row .col-xs-8,
.css-base .modulebox .row .col-xs-9,
.css-base .modulebox .row .col-xs-10,
.css-base .modulebox .row .col-xs-11,
.css-base .modulebox .row .col-xs-12,
.css-base .modulebox .row .col-sm-1,
.css-base .modulebox .row .col-sm-2,
.css-base .modulebox .row .col-sm-3,
.css-base .modulebox .row .col-sm-4,
.css-base .modulebox .row .col-sm-5,
.css-base .modulebox .row .col-sm-6,
.css-base .modulebox .row .col-sm-7,
.css-base .modulebox .row .col-sm-8,
.css-base .modulebox .row .col-sm-9,
.css-base .modulebox .row .col-sm-10,
.css-base .modulebox .row .col-sm-11,
.css-base .modulebox .row .col-sm-12,
.css-base .modulebox .row .col-md-1,
.css-base .modulebox .row .col-md-2,
.css-base .modulebox .row .col-md-3,
.css-base .modulebox .row .col-md-4,
.css-base .modulebox .row .col-md-5,
.css-base .modulebox .row .col-md-6,
.css-base .modulebox .row .col-md-7,
.css-base .modulebox .row .col-md-8,
.css-base .modulebox .row .col-md-9,
.css-base .modulebox .row .col-md-10,
.css-base .modulebox .row .col-md-11,
.css-base .modulebox .row .col-md-12,
.css-base .modulebox .row .col-lg-1,
.css-base .modulebox .row .col-lg-2,
.css-base .modulebox .row .col-lg-3,
.css-base .modulebox .row .col-lg-4,
.css-base .modulebox .row .col-lg-5,
.css-base .modulebox .row .col-lg-6,
.css-base .modulebox .row .col-lg-7,
.css-base .modulebox .row .col-lg-8,
.css-base .modulebox .row .col-lg-9,
.css-base .modulebox .row .col-lg-10,
.css-base .modulebox .row .col-lg-11,
.css-base .modulebox .row .col-lg-12 {
  padding-left: 0;
  padding-right: 0;
}
.css-base .modulebox .row > div > .image {
  display: block;
  height: auto;
  width: 100%;
  margin: 0 0 15px 0;
}
.css-base .modulebox .row > div:first-child > .image:first-child {
  margin-top: -15px;
}
@media screen and (min-width: 768px) {
  .css-base .modulebox .row {
    margin-left: 7.5px;
    margin-right: 7.5px;
  }
  .css-base .modulebox .row:before,
  .css-base .modulebox .row:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  .css-base .modulebox .row:after {
    clear: both;
  }
  .css-base .modulebox .row .col-xs-1,
  .css-base .modulebox .row .col-xs-2,
  .css-base .modulebox .row .col-xs-3,
  .css-base .modulebox .row .col-xs-4,
  .css-base .modulebox .row .col-xs-5,
  .css-base .modulebox .row .col-xs-6,
  .css-base .modulebox .row .col-xs-7,
  .css-base .modulebox .row .col-xs-8,
  .css-base .modulebox .row .col-xs-9,
  .css-base .modulebox .row .col-xs-10,
  .css-base .modulebox .row .col-xs-11,
  .css-base .modulebox .row .col-xs-12,
  .css-base .modulebox .row .col-sm-1,
  .css-base .modulebox .row .col-sm-2,
  .css-base .modulebox .row .col-sm-3,
  .css-base .modulebox .row .col-sm-4,
  .css-base .modulebox .row .col-sm-5,
  .css-base .modulebox .row .col-sm-6,
  .css-base .modulebox .row .col-sm-7,
  .css-base .modulebox .row .col-sm-8,
  .css-base .modulebox .row .col-sm-9,
  .css-base .modulebox .row .col-sm-10,
  .css-base .modulebox .row .col-sm-11,
  .css-base .modulebox .row .col-sm-12,
  .css-base .modulebox .row .col-md-1,
  .css-base .modulebox .row .col-md-2,
  .css-base .modulebox .row .col-md-3,
  .css-base .modulebox .row .col-md-4,
  .css-base .modulebox .row .col-md-5,
  .css-base .modulebox .row .col-md-6,
  .css-base .modulebox .row .col-md-7,
  .css-base .modulebox .row .col-md-8,
  .css-base .modulebox .row .col-md-9,
  .css-base .modulebox .row .col-md-10,
  .css-base .modulebox .row .col-md-11,
  .css-base .modulebox .row .col-md-12,
  .css-base .modulebox .row .col-lg-1,
  .css-base .modulebox .row .col-lg-2,
  .css-base .modulebox .row .col-lg-3,
  .css-base .modulebox .row .col-lg-4,
  .css-base .modulebox .row .col-lg-5,
  .css-base .modulebox .row .col-lg-6,
  .css-base .modulebox .row .col-lg-7,
  .css-base .modulebox .row .col-lg-8,
  .css-base .modulebox .row .col-lg-9,
  .css-base .modulebox .row .col-lg-10,
  .css-base .modulebox .row .col-lg-11,
  .css-base .modulebox .row .col-lg-12 {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .css-base .modulebox .row > div:first-child > .image:first-child {
    margin-top: 0;
  }
  .css-base .modulebox .row image,
  .css-base .modulebox .row h3,
  .css-base .modulebox .row p {
    margin: 0 0 15px 0;
  }
}
.css-base .right-column .modulebox:first-child {
  border-top-style: none;
}
.css-base .right-column .modulebox:first-child:before {
  background-color: #0068b4;
  content: "";
  display: block;
  height: 4px;
  margin: 0 -1px;
  position: relative;
  top: -15px;
}
.css-base .right-column .image {
  display: block;
  height: auto;
  margin: -15px 0 15px 0;
  width: 100%;
}
/*# sourceMappingURL=grundfos.map */