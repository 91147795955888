$theme-colors: (
  "primary": #11497b,
  "primary-text": #8cbbdd
);

@import "~bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: GrundfosTheSans;
  src: url(/fonts/GrundfosTheSansV2_8_.woff) format("woff"),
    url(/fonts/GrundfosTheSansV2_8_.svg) format("svg"),
    url(/fonts/GrundfosTheSansV2_8_.eot);
  font-style: normal;
  font-display: swap;
}

.site-name {
  color: map-get($theme-colors, "primary-text");
  float: right;
  font-family: GrundfosTheSans, Arial, sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 18px;
  margin-left: 5px;
  margin-top: 14px;
  padding-left: 5px;
  text-transform: uppercase;
}

#global-navigation-left,
#global-navigation-right {
  li {
    padding: 10px 0 0 0;
    margin-right: 5px;
    a {
      font-size: 13px;
      line-height: 20px;
    }
  }
}
